import React, { Fragment } from "react";
import classNames from "classnames";
import { Field, ErrorMessage, useField } from "formik";

import "../styles/elements.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormTextBox = (props) => {
  const [, meta] = useField(props.name);

  return (
    <Field name={props.name} {...props}>
      {({ field }) => (
        <Fragment>
          {props.label && (
            <label className="has-text-weight-normal is-size-7 mb-1 is-block">
              {props.label}
              {props.isOptional && (
                <span className="has-text-grey is-italic"> (Optional)</span>
              )}
            </label>
          )}
          <div className="form-text">
            <input
              type={props.type || "text"}
              name={field.name}
              className={classNames(`input is-${props?.size} mb-`, {
                "is-danger": meta?.touched && meta?.error,
                "has-icon": props.icon,
              })}
              onKeyUp={(event) => {
                if (props?.onTextChange) props.onTextChange(event);
              }}
              placeholder={props.placeholder}
              {...field}
            />
            {props.icon && (
              <span
                className={`${
                  props.size === "small"
                    ? "form-text-icon-small"
                    : "form-text-icon"
                }`}
              >
                <FontAwesomeIcon icon={props.icon} />
              </span>
            )}
            <p className="has-text-danger mb-3 is-size-7">
              <ErrorMessage name={props.name} />
            </p>
          </div>
        </Fragment>
      )}
    </Field>
  );
};

export default FormTextBox;
