import React, { useReducer } from "react";
import { AppReducer } from "./AppReducer";
import { appState } from "./appState";

import Modal from "components/Layouts/Modal";
import ContactMe from "elements/ContactMe";
import Toast from "elements/Toast";

const AppContext = React.createContext(appState);

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, { ...appState });

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
      <ContactMe dispatch={dispatch} state={state} />
      <Modal dispatch={dispatch} modalState={state?.modal} />
      <Toast dispatch={dispatch} toastState={state?.toast} />
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
