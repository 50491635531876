export const validations = {
  requiredMessage: "This field is required.",
  invalidFullName: "Please enter your full name.",
  passwordMinimumCharacters: "Your password must be at least 8 characters.",
  confirmPasswordMismatch: "Confirm password do not match.",
  invalidEmail: "Please enter a valid email address.",
  needsUpperAndLowerCase:
    "Password should contain at least an upper and lower case.",
  needsNumber: "Password should contain at least one numbers",
  needsSpecialCharacter: "Password needs at least one special characters",
  passwordDoesNotMatch: "Password does not match.",
};
