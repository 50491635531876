import React, { useEffect } from "react";
import classNames from "classnames";

import "./styles/elements.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faXmark } from "@fortawesome/free-solid-svg-icons";

const Toast = ({ dispatch, toastState }) => {
  useEffect(() => {
    setTimeout(() => {
      if (toastState?.isActive) dispatch({ type: "TOGGLE_TOAST" });
    }, 3500);

    //eslint-disable-next-line
  }, [toastState?.isActive]);

  let icon = faCheckDouble;
  if (toastState?.color === "danger") icon = faXmark;

  return (
    <div
      className={classNames(
        `toast has-background-${
          toastState?.color || "grey"
        }-dark has-text-white`,
        { "toast-open": toastState?.isActive }
      )}
    >
      {toastState?.color && (
        <span className="mr-4">
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      {toastState?.message}
    </div>
  );
};

export default Toast;
