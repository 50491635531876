import { Link } from "gatsby";
import classNames from "classnames";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { gsap, Expo, Power4 } from "gsap";

import Button from "./Button";
import FormTextBox from "./Form/FormTextBox";
import FormTextArea from "./Form/FormTextArea";

import "./styles/elements.scss";
import { contactMeLinks } from "./utils/contactMeLinks";
import { sendContactForm } from "./services/sendContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { contactMeValidationSchema } from "./schema/contactMeValidationSchema";

const ContactMe = ({ dispatch, state }) => {
  const [loading, setLoading] = useState(false);

  const handleToggleForm = () => {
    let contactFormTimeline = gsap.timeline();
    let contactFormBackgroundTimeline = gsap.timeline();

    contactFormTimeline.to(".contact-me-form-content", {
      ease: Power4.easeOut,
      duration: 0.3,
      opacity: 0,
      y: 20,
    });

    contactFormTimeline.to(".contact-me-form-container", {
      duration: 0.5,
      height: "0rem",
      ease: Expo.easeOut,
    });

    contactFormTimeline.to(".contact-me-form-content", {
      duration: 0,
      opacity: 1,
      y: 0,
    });

    contactFormBackgroundTimeline.to(".contact-me-container", {
      duration: 1,
      backgroundColor: "rgba(1, 1, 1, 0)",
    });

    setTimeout(() => {
      dispatch({ type: "TOGGLE_CONTACT_ME" });
    }, 250);
  };

  const handleFormSubmission = (values, { resetForm }) => {
    setLoading(true);
    sendContactForm({
      values,
      callback: () => {
        resetForm();
        setLoading(false);
        handleToggleForm();
        dispatch({
          type: "TOGGLE_TOAST",
          payload: {
            color: "success",
            message:
              "Thanks for sending a message. I'll get back to you as soon as possible.",
          },
        });
      },
    });
  };

  return (
    <div
      className={classNames("contact-me-container", {
        "is-active": state?.isContactMeActive,
      })}
    >
      <div className="contact-me-form-container">
        <button
          className="close-button delete"
          onClick={handleToggleForm}
          aria-label="close form"
        />
        <div className="contact-me-form-content">
          <div className="contact-me-header">
            <h1 className="title is-4 mb-1">Contact Me</h1>
            <p className="is-size-6 has-text-grey">
              Let's get this conversation started!
            </p>
          </div>
          <div className="contact-me-form">
            <Formik
              initialValues={{
                body: "",
                subject: "",
                fullName: "",
              }}
              onSubmit={handleFormSubmission}
              validationSchema={contactMeValidationSchema}
            >
              {() => (
                <Form>
                  <div className="mb-5">
                    <FormTextBox name="fullName" label="Full Name" />
                    <FormTextBox name="subject" label="Subject" />
                    <FormTextArea name="body" label="Body" noResize />
                  </div>
                  <Button
                    isFullWidth
                    type="submit"
                    color="primary"
                    isLoading={loading}
                  >
                    <span className="mr-3">
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                    Send Message
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="alternate-contacts">
            <div className="alternate-contacts-divider has-text-grey">
              Contact me through the following media
            </div>
            <div className="is-flex is-justify-content-center alternate-contacts">
              {contactMeLinks?.map((link) => (
                <Link
                  to={link?.link}
                  target="_blank"
                  className="contact-me-icon has-text-white is-size-5"
                >
                  <FontAwesomeIcon icon={link?.icon} />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMe;
