import { appState } from "./appState";

export const AppReducer = (state, action) => {
  switch (action?.type) {
    case "TOGGLE_SIDE_NAVIGATION":
      return {
        ...state,
        isSideNavOpen: !state?.isSideNavOpen,
      };

    case "SAVE_USER": {
      return {
        ...state,
        user: action?.payload,
      };
    }

    case "DISABLE_INITIAL_LOAD": {
      return {
        ...state,
        isInitialLoad: false,
      };
    }

    case "DISABLE_LINKS_LOAD": {
      return {
        ...state,
        isLinksLoaded: false,
      };
    }

    case "HIDE_BANNER":
      return {
        ...state,
        isBannerVisible: false,
      };

    case "TOGGLE_MODAL":
      return {
        ...state,
        modal: { ...action?.payload, isActive: !state?.modal?.isActive },
      };

    case "TOGGLE_CONTACT_ME":
      return {
        ...state,
        isContactMeActive: !state?.isContactMeActive,
      };

    case "TOGGLE_PROJECT_DETAILS":
      return {
        ...state,
        isProjectDetailsActive: !state?.isProjectDetailsActive,
      };

    case "TOGGLE_TOAST":
      return {
        ...state,
        toast: { ...action?.payload, isActive: !state?.toast?.isActive },
      };

    default:
      return { ...appState, ...state };
  }
};
