import * as Yup from "yup";

import { validations } from "../../../services/validations";
let { requiredMessage } = validations;

export const contactMeValidationSchema = () =>
  Yup.object().shape({
    fullName: Yup.string().required(requiredMessage),
    subject: Yup.string().required(requiredMessage),
    body: Yup.string().required(requiredMessage),
  });
