import React from "react";
import classNames from "classnames";

import "./styles/elements.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({
  size,
  text,
  color,
  icon,
  type,
  inverted,
  children,
  outlined,
  iconLeft,
  textLeft,
  alignment,
  iconRight,
  className,
  isLoading,
  isDisabled,
  textWeight,
  handleClick,
  isFullWidth,
}) => {
  return (
    <button
      type={type || "button"}
      className={classNames(
        `button mb-3 is-${size} is-${color}`,
        className,
        {
          "is-loading": isLoading,
        },
        { "is-inverted": inverted },
        { "is-outlined": outlined },
        { "is-fullwidth": isFullWidth }
      )}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <div
        className={`has-text-weight-${textWeight} is-flex is-justify-content-${
          alignment || "space-between"
        } button-child`}
      >
        <div>
          {iconLeft && (
            <span className={`${text ? "mr-3" : ""}`}>
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
          {textLeft && <span>{text || children}</span>}
        </div>
        <div>{!textLeft && (text || children)}</div>
        <div>
          {iconRight && (
            <span className={`${text ? "ml-3" : ""}`}>
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

export default Button;
