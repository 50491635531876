import {
  faDev,
  faLinkedinIn,
  faHackerrank,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

export const contactMeLinks = [
  { icon: faEnvelope, link: "mailto:developer.jcarlos@gmail.com" },
  { icon: faLinkedinIn, link: "https://www.linkedin.com/in/jtc0913/" },
  { icon: faDev, link: "https://dev.to/jtcarlos" },
  { icon: faHackerrank, link: "https://www.hackerrank.com/developer_jcarl1" },
];
