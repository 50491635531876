import React from "react";
import classNames from "classnames";

import "./style/layouts.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ dispatch, modalState }) => {
  const handleCloseModal = () => {
    dispatch({ type: "TOGGLE_MODAL" });
  };

  return (
    <div className={classNames("modal", { "is-active": modalState?.isActive })}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <section className="modal-card-body p-5">
          <button
            aria-label="close"
            onClick={handleCloseModal}
            className="modal-close-button"
          >
            <span className="is-size-5">
              <FontAwesomeIcon icon={faCircleXmark} />
            </span>
          </button>
          <h4 className="title is-5">{modalState?.header}</h4>
          {modalState?.body}
        </section>
      </div>
    </div>
  );
};

export default Modal;
