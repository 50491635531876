import emailjs from "@emailjs/browser";
import moment from "moment";

export const sendContactForm = async ({ values, callback }) => {
  emailjs
    .send(
      "jtcarlos",
      "jtcarlos-contact-form",
      { ...values, time: moment().format("llll") },
      "vbZ_ikKqg5PQr0leM"
    )
    .then(() => {
      if (callback) callback();
    });
};
