import React from "react";

import { initiateFirebase } from "services/firebase";
import { AppProvider } from "context/AppContext";

export const wrapRootElement = ({ element }) => {
  initiateFirebase();

  return <AppProvider>{element}</AppProvider>;
};
