import React from "react";
import classNames from "classnames";
import { Field, ErrorMessage, useField } from "formik";

import "../styles/elements.scss";

const FormTextArea = (props) => {
  const [, meta] = useField(props.name);

  return (
    <Field name={props.name} {...props}>
      {({ field }) => (
        <div className="form-textarea">
          {props.label && (
            <label className="has-text-weight-normal is-size-7 mb-1 is-block">
              {props.label}
              {props.isOptional && (
                <span className="has-text-grey is-italic"> (Optional)</span>
              )}
            </label>
          )}
          <textarea
            className={classNames("textarea is-regular", {
              "has-fixed-size": props?.fixedSize,
              "no-resize": props?.noResize,
              "is-danger": meta?.touched && meta?.error,
            })}
            rows={props.row}
            cols={props.cols}
            placeholder={props.placeholder}
            value={props.value}
            {...field}
            maxLength={props.maxLength}
          ></textarea>
          <p className="has-text-danger mb-3 is-size-7">
            <ErrorMessage name={props.name} />
          </p>
        </div>
      )}
    </Field>
  );
};

export default FormTextArea;
