import { getFirestore } from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { isBrowser } from "services/general";
import {
  GATSBY_FIREBASE_APP_ID,
  GATSBY_FIREBASE_API_KEY,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_AUTH_DOMAIN,
  GATSBY_FIREBASE_MEASUREMENT_ID,
  GATSBY_FIREBASE_STORAGE_BUCKET,
  GATSBY_FIREBASE_MESSAGING_SENDER_ID,
} from "gatsby-env-variables";

const FIREBASE_CONFIGURATION = {
  appId: GATSBY_FIREBASE_APP_ID,
  apiKey: GATSBY_FIREBASE_API_KEY,
  projectId: GATSBY_FIREBASE_PROJECT_ID,
  authDomain: GATSBY_FIREBASE_AUTH_DOMAIN,
  storageBucket: GATSBY_FIREBASE_STORAGE_BUCKET,
  measurementId: GATSBY_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: GATSBY_FIREBASE_MESSAGING_SENDER_ID,
};

let firebaseInstance = null;

export const initiateFirebase = () => {
  if (isBrowser()) {
    if (firebaseInstance) return firebaseInstance;

    firebaseInstance = firebase.initializeApp(FIREBASE_CONFIGURATION);
    return firebaseInstance;
  }

  return null;
};

export const getCurrentFirestore = () => {
  let firestore = {};
  if (isBrowser()) firestore = getFirestore();

  return firestore;
};
