export const appState = {
  user: {},
  isBannerVisible: true,
  isSideNavOpen: false,
  isContactMeActive: false,
  isProjectDetailsActive: false,
  isInitialLoad: true,
  isLinksLoaded: true,
  enablePlaneWave: true,
  modal: {
    isActive: false,
  },
  toast: {
    isActive: false,
  },
};
